.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  min-width: 600px;
}

.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  margin: 10px;
}

.h2 {
    margin-bottom: 5px;
}

.wallet {
  font-size: 18px;
  margin-bottom: 30px;
}

.link {
  text-decoration: none;
}