.userTile {
  min-width: 160px;
  min-height: 180px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  padding: 20px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 200ms;
  animation: createBox 0.25s;
  cursor: pointer;
}

.userTile:hover {
  transform: scale3d(1.04, 1.04, 1.04);
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.userHeader {
  margin-bottom: 10px;
}

.profilePic {
  border-radius: 4px;
  width: 150px;
  height: 150px;
}
