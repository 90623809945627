.App {
  text-align: center;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faucetHeader {
  font-size: 40px;
}

.searchForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchInputs {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.searchRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.searchLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.searchInput {
  margin: 10px;
  font-size: 25px;
  border-radius: 4px;
  width: 200px;
}

.label {
  font-size: 24px;
  display: none;
}

.alerts {
  height: 30px;
}

.userList {
  margin-left: 200px;
  margin-right: 200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}